<template>
    <b-overlay :show="loading">
        <b-row class="mb-2">
            <!-- Header -->
            <b-col cols="12" class="mb-2">
                <b-button variant="primary" class="mr-2" v-if="allowCreate()" @click.prevent="add">Add</b-button>
                <!-- <b-button variant="outline-danger" @click.prevent="confirmCancel" :disabled="selectedPenyimpanan.length < 1">
                    Batalkan Penyimpanan <span v-if="selectedPenyimpanan.length > 0">({{selectedPenyimpanan.length}})</span>
                </b-button> -->
            </b-col>
            <b-col sm="12" md="6">
                <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50">Per page</label>
                    <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        class="w-50"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
                <b-form-group
                :label="$t('Filter')"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
                >
                    <b-input-group size="sm">
                        <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        
                        />
                        <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <!-- / -->

        </b-row>
        <!-- Body -->
        <b-card>
            <b-row>
                <b-col cols="12">
                    <b-table :per-page="perPage" :current-page="currentPage" :filter="filter" responsive :fields="fields" :items="stocks">
                        <template #cell(checkbox)="{item}">
                            <b-form-checkbox v-model="selectedPenyimpanan" :value="item"></b-form-checkbox>
                        </template>
                        <template #cell(no)="{index}">
                            {{++index}}
                        </template>
                        <template #cell(jenis)="{item}">
                            <div v-if="item.asset">
                                {{ item.asset.jenis == 1 ? 'TETAP' : 'BPH' }}
                            </div>
                        </template>
                        <template #cell(nama)="{item}">
                            {{ item.asset ? item.asset.nama : '' }}
                        </template>
                        <template #cell(kode)="{item}">
                            {{ item.asset ? item.asset.kode : '' }}
                        </template>
                        <template #cell(harga_dasar)="{item}">
                            {{ item.asset ? formatRupiah(item.asset.harga_dasar) : 0 }}
                        </template>
                        <template #cell(stocks)="{item}">
                            {{ getTotal(item.stocks) }}
                        </template>
                        <template #cell(satuan)="{item}">
                            {{ item.asset && item.asset.satuan ? item.asset.satuan.satuan : '' }}
                        </template>
                        <template #cell(actions)="{item}">
                            <div class="flex align-items-center justify-center">
                                <b-button
                                    size="sm"
                                    @click="$router.push(`/asset-penyimpanan/detail/${item.id}`)"
                                    class="mr-1"
                                    variant="outline-info"
                                    >
                                    <feather-icon icon="EyeIcon" />
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="12">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="center"
                        size="sm"
                        class="my-0"
                    />
                </b-col>
            </b-row>
        </b-card>
        <!-- / -->
    </b-overlay>
</template>
<script>
import {BOverlay, BFormCheckbox, BFormTextarea, BRow, BBadge, BCol, BCard, BPagination, BTable, BFormGroup, BInputGroup, BInputGroupAppend, BButton, BFormInput, BFormSelect} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
    components: {
        BOverlay, BFormCheckbox, BFormTextarea, BRow, BBadge, BCol, BCard, BPagination, BTable, BFormGroup, BInputGroup, BInputGroupAppend, BButton, BFormInput, BFormSelect
    },
    data: () => ({
        selectedPenyimpanan: [],
        stocks: [],
        loading: false,
        perPage: 10,
        totalRows: 0,
        currentPage: 1,
        pageOptions: [10, 30, 50],
        filter: null,
        fields: [
            {key: 'no', label: 'No'},
            {key: 'jenis', label: 'Jenis'},
            {key: 'nama', label: 'Nama Aset'},
            {key: 'kode', label: 'Kode'},
            {key: 'harga_dasar', label: 'Harga Dasar'},
            {key: 'stok', label: 'Stok'},
            {key: 'satuan', label: 'Satuan'},
            {key: 'actions', label: '#'}
        ],
        confirmOption: {
            title: 'Anda yakin?',
            text: "Stok sebelumnya akan dikembalikan dari penyimpanan saat ini",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false
        }
    }),
    methods: {
        confirmCancel() {
            this.confirmOption.text = `${this.selectedPenyimpanan.length} Stok Aset akan dibatalkan`
            this.$swal(this.confirmOption)
            .then(res => {
                if(res.value) {
                    this.massCancelPenyimpanan()
                }
            })
        },
        async massCancelPenyimpanan() {
            const payloads = this.selectedPenyimpanan.map(item => ({id: item.id, cancel: 1}))
            try {
                this.loading = true
                await this.$store.dispatch('asset/cancelPenyimpanan', payloads)
                this.getDataPenyimpanan()
                this.loading = false
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        add() {
            this.$router.push('/asset-penyimpanan/add')
        },
        getTotal(stocks) {
            if(!stocks) {
                return 0
            }

            return stocks.reduce((total, item) => total += item.stok, 0)
        },
        async getDataPenyimpanan() {
            const params = {
                order: 'desc'
            }
            if(this.myGudang) {
                params.gudang_id = this.myGudang.id
            }

            this.stocks = await this.$store.dispatch('asset/getStock', params)
            this.totalRows = this.stocks.length
        }
    },
    created() {
        this.loading = true
        this.getDataPenyimpanan()
        this.loading = false
    }
}
</script>